import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {Settings} from '@common/core/config/settings.service';
import {Translations} from '@common/core/translations/translations.service';
import {HomepageContent} from './homepage-content';
import {MetaTagsService} from '@common/core/meta/meta-tags.service';

interface HomepageStats {
    links: number;
    clicks: number;
    users: number;
}

@Component({
    selector: 'landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingComponent implements OnInit {
    public stats$ = new BehaviorSubject<HomepageStats>(null);
    public content$ = new ReplaySubject<HomepageContent>(1);

    constructor(
        private http: AppHttpClient,
        public settings: Settings,
        private i18n: Translations,
        private metaTags: MetaTagsService,
    ) {}

    ngOnInit() {
        this.settings.all$().subscribe(() => {
            this.content$.next(this.settings.getJson('homepage.appearance'));
        });
        this.http.get('homepage/stats').subscribe(response => {
            this.stats$.next(response.stats);
            this.metaTags.latestMetaTags$.next(response.seo);
        });
    }

    public copyrightText() {
        const year = (new Date()).getFullYear();
        return this.i18n.t('Copyright © :year, All Rights Reserved', {year});
    }

    public scrollToFeatures() {
        document.querySelector('.first-secondary-feature')
            .scrollIntoView({block: 'center', inline: 'center', behavior: 'smooth'});
    }
}
