import { Router } from '@angular/router';
import { Settings } from '../../core/config/settings.service';
import { CommandTypes } from './commands/command-types';
import { createSelectorBox } from './utils/create-selector-box';
import { selectNode } from './utils/select-node';
import { deselectNode } from './utils/deselect-node';
import { filter } from 'rxjs/operators';
import { Toast } from '../../core/ui/toast.service';
import { createCustomCodeNode } from '@common/shared/appearance/utils/create-custom-code-node';
import * as i0 from "@angular/core";
import * as i1 from "../../core/ui/toast.service";
import * as i2 from "@angular/router";
import * as i3 from "../../core/config/settings.service";
export const APPEARANCE_TOKEN = 't50b4BT5hWsvJMr7';
export class AppearanceListenerService {
    constructor(toast, router, settings) {
        this.toast = toast;
        this.router = router;
        this.settings = settings;
        this.active = false;
        this.dom = {};
    }
    init() {
        this.active = window.location.search.includes(`be-preview-mode=${APPEARANCE_TOKEN}`);
        if (!this.active)
            return;
        this.listenForMessages();
        this.blockNotAllowedRoutes();
        this.createDomNodes();
        window.parent.postMessage(APPEARANCE_TOKEN, '*');
    }
    listenForMessages() {
        window.addEventListener('message', e => {
            if (this.isAppearanceEvent(e) && this.eventIsTrusted(e)) {
                this.handleCommand(e.data);
            }
        });
    }
    handleCommand(command) {
        // TODO: fix command data typings (remove "any")
        switch (command.type) {
            case (CommandTypes.Navigate):
                return this.router.navigate([command.route]);
            case (CommandTypes.SetConfig):
                return this.settings.set(command.key, command.value);
            case (CommandTypes.Select):
                return selectNode(command.selector, command.index, this.dom.selectBox);
            case (CommandTypes.Deselect):
                return deselectNode(this.dom.selectBox);
            case (CommandTypes.SetColors):
                return this.dom.colors.setProperty(command.key, command.value);
            case (CommandTypes.SetCustomCss):
                return createCustomCodeNode('css', this.settings.getBaseUrl(true));
            case (CommandTypes.SetCustomJs):
                return createCustomCodeNode('js', this.settings.getBaseUrl(true));
        }
    }
    eventIsTrusted(e) {
        return (new URL(e.origin).hostname) === window.location.hostname;
    }
    isAppearanceEvent(e) {
        const data = e.data;
        return data && (data.type in CommandTypes);
    }
    createDomNodes() {
        this.dom.selectBox = createSelectorBox();
        this.dom.colors = document.documentElement.style;
    }
    blockNotAllowedRoutes() {
        const config = this.settings.get('vebto.admin.appearance');
        this.router.events
            .pipe(filter(e => e.toString().indexOf('NavigationStart') === 0))
            .subscribe((e) => {
            if (e.url === '/')
                return;
            // route exists in config, bail
            const routes = config.navigationRoutes;
            if (routes.find(route => e.url.indexOf(route) > -1))
                return;
            // prevent navigation to routes not specified in config
            const current = this.router.url.split('?')[0];
            this.router.navigate([current], { queryParamsHandling: 'preserve' });
            setTimeout(() => this.toast.open('That page is not supported by the editor.'));
        });
    }
}
AppearanceListenerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppearanceListenerService_Factory() { return new AppearanceListenerService(i0.ɵɵinject(i1.Toast), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Settings)); }, token: AppearanceListenerService, providedIn: "root" });
