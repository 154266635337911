import { Settings } from '../config/settings.service';
import { LazyLoaderService } from '../utils/lazy-loader.service';
import { AppHttpClient } from '../http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
import * as i2 from "../http/app-http-client.service";
import * as i3 from "../utils/lazy-loader.service";
const RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js?render=';
export class RecaptchaService {
    constructor(settings, http, lazyLoader) {
        this.settings = settings;
        this.http = http;
        this.lazyLoader = lazyLoader;
    }
    enabledFor(page) {
        return this.settings.get('recaptcha.site_key') &&
            this.settings.get('recaptcha.secret_key') &&
            this.settings.get(`recaptcha.enable_for_${page}`);
    }
    load() {
        return this.lazyLoader
            .loadScript(RECAPTCHA_URL + this.settings.get('recaptcha.site_key'));
    }
    verify(page) {
        return new Promise(resolve => {
            if (!grecaptcha)
                return false;
            grecaptcha.ready(() => {
                grecaptcha.execute(this.settings.get('recaptcha.site_key'), { action: page })
                    .then(token => {
                    this.http.post('recaptcha/verify', { token })
                        .subscribe(response => resolve(response.success));
                });
            });
        });
    }
}
RecaptchaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecaptchaService_Factory() { return new RecaptchaService(i0.ɵɵinject(i1.Settings), i0.ɵɵinject(i2.AppHttpClient), i0.ɵɵinject(i3.LazyLoaderService)); }, token: RecaptchaService, providedIn: "root" });
