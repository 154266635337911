import { Router } from '@angular/router';
import { Settings } from '../../config/settings.service';
import { CustomPageComponent } from './custom-page/custom-page.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../config/settings.service";
export class CustomHomepage {
    constructor(router, settings) {
        this.router = router;
        this.settings = settings;
        this.defaultComponents = [
            { path: 'login', redirectTo: '/login', pathMatch: 'full' },
            { path: 'register', redirectTo: '/register', pathMatch: 'full' },
        ];
    }
    select(userRoutes = []) {
        this.defaultComponents = this.defaultComponents.concat(userRoutes);
        const type = this.settings.get('homepage.type', 'default'), value = this.settings.get('homepage.value');
        if (type === 'default' || !value) {
            return;
        }
        else if (type === 'component') {
            return this.setComponentAsHomepage(value);
        }
        else if (type === 'page') {
            return this.setCustomPageAsHomepage(value);
        }
    }
    isOnlyForGuests() {
        const type = this.settings.get('homepage.type', 'default'), value = this.settings.get('homepage.value');
        return type === 'component' && (value === 'login' || value === 'register');
    }
    getComponents() {
        return this.defaultComponents;
    }
    setComponentAsHomepage(name) {
        const route = this.defaultComponents.find(comp => comp.path === name);
        this.addRoute(Object.assign({}, route));
    }
    setCustomPageAsHomepage(id) {
        const route = { component: CustomPageComponent, data: { id } };
        this.addRoute(route);
    }
    addRoute(route) {
        const parent = this.getParentHomeRoute();
        route = this.prepareRoute(route);
        // use child routes if parent exists, otherwise use base router config
        const routes = parent ? parent.children : this.router.config;
        // remove already existing home route
        const i = routes.findIndex(r => r.path === '');
        // start new route specified by user
        if (i > -1) {
            routes[i] = route;
        }
        else {
            routes.unshift(route);
        }
    }
    getParentHomeRoute() {
        return this.router.config.find(route => {
            return route.data && route.data.name === 'parent-home-route';
        });
    }
    prepareRoute(route) {
        route.path = '';
        if (!route.data)
            route.data = {};
        route.data.name = 'home';
        return route;
    }
}
CustomHomepage.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomHomepage_Factory() { return new CustomHomepage(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Settings)); }, token: CustomHomepage, providedIn: "root" });
