import { Settings } from '../config/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
export class LazyLoaderService {
    constructor(config) {
        this.config = config;
        this.loadedScripts = {};
    }
    /**
     * Load js script and return promise resolved on script load event.
     */
    loadScript(url, params = {}) {
        // script is already loaded, return resolved promise
        if (this.loadedScripts[url] === 'loaded' && !params.force) {
            return new Promise((resolve) => resolve());
            // script has never been loaded before, load it, return promise and resolve on script load event
        }
        else if (!this.loadedScripts[url] || (params.force && this.loadedScripts[url] === 'loaded')) {
            this.loadedScripts[url] = new Promise((resolve, reject) => {
                const s = document.createElement('script');
                s.async = true;
                s.id = params.id || url.split('/').pop();
                s.src = url.indexOf('//') > -1 ? url : this.config.getAssetUrl() + url;
                s.onload = () => {
                    this.loadedScripts[url] = 'loaded';
                    resolve();
                };
                document.body.appendChild(s);
            });
            return this.loadedScripts[url];
            // script is still loading, return existing promise
        }
        else {
            return this.loadedScripts[url];
        }
    }
}
LazyLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LazyLoaderService_Factory() { return new LazyLoaderService(i0.ɵɵinject(i1.Settings)); }, token: LazyLoaderService, providedIn: "root" });
